.Card {
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(1, 1, 1, 0.1), 0 1px 3px rgba(1, 1, 1, 0.15);
  border-radius: 20px;
  padding: 0 20px;
  width: 300px;
  max-width: 90%;
  color: #333333;
  margin-bottom: 20px;
}
