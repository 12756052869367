.AppExhibitSupport {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }
  
  .MobileStoreButtonWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .DownloadProblems {
    margin-bottom: 80px;
  }
  
  .WhereToGetUpdates {
    margin-bottom: 80px;
  }
  
  .Bugs {
    margin-bottom: 80px;
  }
  
  .Features {
    margin-bottom: 80px;
  }
  
  .LinkDark:link {
    color: #2bd9fe;
  }
  
  .LinkDark:visited {
    color: #49b6ff;
  }
  
  .LinkLight:link {
    color: #0000c3;
  }
  
  .LinkLight:visited {
    color: #4e177f;
  }
  