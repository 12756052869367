.Home {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #000000;
}

.Apps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.Apps > img {
  margin-right: 20px;
  border-radius: 5px;
}

.Social {
  margin: 20px 0;
}

.Github {
  margin-right: 10px;
}
